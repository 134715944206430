jQuery(document).ready(function($) {
/* ====================== CURSORS ======================  */
    var cursor = document.querySelector('.cursor');
    var showReel = document.querySelectorAll('.showreel .showreel__video');
    var projectBlock = document.querySelectorAll('.project-block');
    var allProjectsblock = document.querySelectorAll('.image-feature--projects');
    var headingContentRow = document.querySelectorAll('.heading-content-row');
    var sliderBLock = document.querySelectorAll('.number-slider .swiper, .sc-image-slider .swiper, .our-values__slider .swiper, .p-image-slider .swiper, .p-results .swiper, .services-slider .swiper');
    var footerConnect = document.querySelectorAll('.footer__top');
    var hideCursor = document.querySelectorAll('.cursor-hide');

    document.addEventListener('mousemove', function(e){
      var x = e.clientX;
      var y = e.clientY;
      cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
    });

    if(showReel.length) {
      showReel.forEach(item => {
        item.addEventListener('mouseover', () => {
          cursor.classList.add('cursor--video');

          if (item.paused == false) {
            cursor.classList.add('cursor--video-pause');
          } else {
            cursor.classList.add('cursor--video-play');
          }
        });
        item.addEventListener('mouseleave', () => {
          cursor.classList.remove('cursor--video');
          cursor.classList.remove('cursor--video-pause');
          cursor.classList.remove('cursor--video-play');
        });
      });

      $('.showreel .showreel__video').on('click', function(e) {
         if (this.paused == false) {
            this.pause();
            cursor.classList.add('cursor--video-play');
            cursor.classList.remove('cursor--video-pause');
        } else {
            this.play();
            cursor.classList.add('cursor--video-pause');
            cursor.classList.remove('cursor--video-play');
          }
      });
    }

    if(projectBlock.length) {
      projectBlock.forEach(item => {
        item.addEventListener('mouseover', () => {
          cursor.classList.add('cursor--project');
        });
        item.addEventListener('mouseleave', () => {
          cursor.classList.remove('cursor--project');
        });
      });
    }

    if(allProjectsblock.length) {
      allProjectsblock.forEach(item => {
        item.addEventListener('mouseover', () => {
          cursor.classList.add('cursor--allprojects');
        });
        item.addEventListener('mouseleave', () => {
          cursor.classList.remove('cursor--allprojects');
        });
      });
    }

    if(headingContentRow.length) {
      headingContentRow.forEach(item => {
        item.addEventListener('mouseover', () => {
          cursor.classList.add('cursor--connect');
        });
        item.addEventListener('mouseleave', () => {
          cursor.classList.remove('cursor--connect');
        });
      });
    }

    if(sliderBLock.length) {
      sliderBLock.forEach(item => {
        item.addEventListener('mouseover', () => {
          cursor.classList.add('cursor--drag');
        });
        item.addEventListener('mouseleave', () => {
          cursor.classList.remove('cursor--drag');
        });
      })
    };

    if(footerConnect.length) {
      footerConnect.forEach(item => {
        item.addEventListener('mouseover', () => {
          cursor.classList.add('cursor--footer-connect');
        });
        item.addEventListener('mouseleave', () => {
          cursor.classList.remove('cursor--footer-connect');
        });
      })
    };


    if(hideCursor.length) {
      hideCursor.forEach(item => {
        item.addEventListener('mouseover', () => {
          cursor.classList.add('cursor--hide');
        });
        item.addEventListener('mouseleave', () => {
          cursor.classList.remove('cursor--hide');
        });
      })
    };
});
